var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
// prodcat.data.notifyMeBinded = false;
(function ($) {
  $(document).on('inv_status_display:updated', '.js-product', function (e) {
    var $statusList = $('.js-inv-status-list', $(this));
    var skuBaseId = $statusList.data('sku-base-id');
    var skuData = prodcat.data.getSku(skuBaseId);
    var enableCs = settings.common.display_notify_cs;
    var undef;

    if ((skuData !== undef && skuData.INVENTORY_STATUS === 2) || (enableCs && skuData.INVENTORY_STATUS === 3)) {
      Drupal.behaviors.notifyMeV1.show(skuData, skuBaseId);
    } else {
      // if(skuData.INVENTORY_STATUS != 2) {
      Drupal.behaviors.notifyMeV1.hide(skuData);
    }
  });

  Drupal.behaviors.notifyMeV1 = {
    show: function (skuData, skuBaseId) {
      var $notifyMeAlertToShow = $('.js-notify-me-alert');
      var enableCs = settings.common.display_notify_cs;

      if ($notifyMeAlertToShow.length > 0) {
        if (skuData.INVENTORY_STATUS === 2 || (enableCs && skuData.INVENTORY_STATUS === 3)) {
          $notifyMeAlertToShow.show();
          Drupal.behaviors.notifyMeV1.bindNotifyMeLink($notifyMeAlertToShow, skuBaseId);
        }
      }
    },
    hide: function (skuData) {
      var $notifyMeAlertToShow = $('.js-notify-me-alert');

      if ($notifyMeAlertToShow.length > 0) {
        $notifyMeAlertToShow.hide();
      }
    },
    bindNotifyMeLink: function ($notifyMeAlertToShow, skuBaseId) {
      $notifyMeAlertToShow
        .find('a')
        .unbind()
        .click(function (e, link) {
          e.preventDefault();
          $(link).unbind();
          var file = '/waitlist/signup.tmpl?SKU_BASE_ID=' + skuBaseId + '&REQUEST_TYPE=BIS';
          var options = {
            includeBackground: false,
            includeCloseLink: true,
            hideOnClickOutside: true,
            width: '500px',
            height: '350px'
          };

          options.content =
            '<iframe class="waitlist-iframe-wrapper" scrolling="no" width="100%" height="90%" frameborder="0" src="' +
            file +
            '"></iframe>';
          $.colorbox.remove();
          generic.overlay.launch(options);
        });
    }
  };
})(jQuery);
